import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import useDetails from '../../hooks/useDetails'
import 'datatables.net';
import 'datatables.net-dt/css/jquery.dataTables.css';
import $ from "jquery"
import api from '../../api/api';
import SliderCarousel from '../../components/SliderCarousel';

const Library = () => {
    const [loading, setLoading] = useState()
    const [library, setLibrary] = useState()
    const {data:details} = useDetails()
    
    // $(function() {
    //     $('#datatable2').DataTable();
    //   })

    const getLibrary = async () => {
        setLoading(true)
        try {
            const {data} = await api.get('/library/')
            setLibrary(data?.results)
            setLoading(false)
        } catch (error) {
            setLoading(false)
        }
    }
    useEffect(() => {
        $('#dataTable2').DataTable();
    },[library]);
  

    useEffect(() => {
        getLibrary()
    }, [])

  return (
    <div class="page-wrapper">
      {details && 
      <div class="page-content-tab">
        <div class="container-fluid">
          <div class="row">
            <div class="col-sm-12">
              <div class="page-title-box">
                <div class="float-right">
                  <ol class="breadcrumb">
                    {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                    <li class="breadcrumb-item active">Library Management</li>
                  </ol>
                </div>
                <h4 class="page-title">My Library</h4>
              </div>
            </div>
          </div>
          <div className='row justify-content-center'>
            <SliderCarousel/>
          </div>
          <div class="row mt-5">
          {library?.map(({description, url, id}) => (
            <div class="col-md-3" key={id}>
                <div class="card">
                    <div class="card-body">
                        <div class="ribbon1 rib1-success">
                            <span class="text-white text-center rib1-success">Free</span>                                        
                        </div>
                        <img src="../assets/images/small/door.png" alt="" class="d-block mx-auto my-4" height="80"/>
                        <div class="d-flex justify-content-between align-items-center my-4">
                            <div>
                                <p class="text-muted mb-2">Name</p>
                                <a href="#" class="header-title">{description}</a>
                            </div>
                            <div> 
                                <ul class="list-inline mb-0 product-review align-self-center">
                                    <li class="list-inline-item"><i class="la la-star text-warning font-16"></i></li>
                                    <li class="list-inline-item"><i class="la la-star text-warning font-16 ml-n2"></i></li>
                                    <li class="list-inline-item"><i class="la la-star text-warning font-16 ml-n2"></i></li>
                                    <li class="list-inline-item"><i class="la la-star text-warning font-16 ml-n2"></i></li>
                                    <li class="list-inline-item"><i class="la la-star-half-o text-warning font-16 ml-n2"></i></li>
                                </ul> 
                            </div>      
                        </div> 
                        <a href={url} target="_blank" rel='noreferrer' class="btn btn-soft-primary btn-block">Open</a>                                                            
                    </div>
                            </div>
                        </div>
                        ))}
            </div>
        </div>

        <div
          class="modal modal-rightbar fade"
          tabindex="-1"
          role="dialog"
          aria-labelledby="MetricaRightbar"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title mt-0" id="MetricaRightbar">
                  Appearance
                </h5>
                <button
                  type="button"
                  class="btn btn-sm btn-soft-primary btn-circle btn-square"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  <i class="mdi mdi-close"></i>
                </button>
              </div>
              <div class="modal-body">
                <ul
                  class="nav nav-pills nav-justified mt-2 mb-4"
                  role="tablist"
                >
                  <li class="nav-item waves-effect waves-light">
                    <a
                      class="nav-link active"
                      data-toggle="tab"
                      href="#ActivityTab"
                      role="tab"
                    >
                      Activity
                    </a>
                  </li>
                  <li class="nav-item waves-effect waves-light">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#TasksTab"
                      role="tab"
                    >
                      Tasks
                    </a>
                  </li>
                  <li class="nav-item waves-effect waves-light">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#SettingsTab"
                      role="tab"
                    >
                      Settings
                    </a>
                  </li>
                </ul>

                <div class="tab-content">
                  <div
                    class="tab-pane active "
                    id="ActivityTab"
                    role="tabpanel"
                  >
                    <div class="bg-light mx-n3">
                      <img
                        src="../assets/images/small/img-1.gif"
                        alt=""
                        class="d-block mx-auto my-4"
                        height="180"
                      />
                    </div>
                    <div class="slimscroll scroll-rightbar">
                      <div class="activity">
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                10 Min ago
                              </small>
                              <a href="#" class="m-0 w-75">
                                Task finished
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>

                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-timer-off bg-soft-pink"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                50 Min ago
                              </small>
                              <a href="#" class="m-0 w-75">
                                Task Overdue
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                            <span class="badge badge-soft-secondary">
                              Design
                            </span>
                            <span class="badge badge-soft-secondary">HTML</span>
                          </div>
                        </div>
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-alert-decagram bg-soft-purple"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                10 hours ago
                              </small>
                              <a href="#" class="m-0 w-75">
                                New Task
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>

                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-clipboard-alert bg-soft-warning"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                yesterday
                              </small>
                              <a href="#" class="m-0 w-75">
                                New Comment
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-clipboard-alert bg-soft-secondary"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                01 feb 2020
                              </small>
                              <a href="#" class="m-0 w-75">
                                New Lead Meting
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>
                        <div class="activity-info">
                          <div class="icon-info-activity">
                            <i class="mdi mdi-checkbox-marked-circle-outline bg-soft-success"></i>
                          </div>
                          <div class="activity-info-text mb-2">
                            <div class="mb-1">
                              <small class="text-muted d-block mb-1">
                                26 jan 2020
                              </small>
                              <a href="#" class="m-0 w-75">
                                Task finished
                              </a>
                            </div>
                            <p class="text-muted mb-2 text-truncate">
                              There are many variations of passages.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="tab-pane" id="TasksTab" role="tabpanel">
                    <div class="m-0">
                      <div id="rightbar_chart" class="apex-charts"></div>
                    </div>
                    <div class="text-center mt-n2 mb-2">
                      <button type="button" class="btn btn-soft-primary">
                        Create Project
                      </button>
                      <button type="button" class="btn btn-soft-primary">
                        Create Task
                      </button>
                    </div>
                    <div class="slimscroll scroll-rightbar">
                      <div class="p-2">
                        <div class="media mb-3">
                          <img
                            src="../assets/images/widgets/project3.jpg"
                            alt=""
                            class="thumb-lg rounded-circle"
                          />
                          <div class="media-body align-self-center text-truncate ml-3">
                            <p class="text-success font-weight-semibold mb-0 font-14">
                              Project
                            </p>
                            <h4 class="mt-0 mb-0 font-weight-semibold text-dark font-18">
                              Payment App
                            </h4>
                          </div>
                        </div>
                        <span>
                          <b>Deadline:</b> 02 June 2020
                        </span>
                        <a href="javascript: void(0);" class="d-block mt-3">
                          <p class="text-muted mb-0">
                            Complete Tasks<span class="float-right">75%</span>
                          </p>
                          <div class="progress mt-2" style={{ height: "4px" }}>
                            <div
                              class="progress-bar bg-secondary"
                              role="progressbar"
                              style={{ width: "75%" }}
                              aria-valuenow="75"
                              aria-valuemin="0"
                              aria-valuemax="100"
                            ></div>
                          </div>
                        </a>
                      </div>
                      <hr class="hr-dashed" />
                    </div>
                  </div>
                  <div class="tab-pane" id="SettingsTab" role="tabpanel">
                    <div class="p-1 bg-light mx-n3">
                      <h6 class="px-3">Account Settings</h6>
                    </div>
                    <div class="p-2 text-left mt-3">
                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch1"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch1"
                        >
                          Auto updates
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch2"
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch2"
                        >
                          Location Permission
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch3"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch3"
                        >
                          Show offline Contacts
                        </label>
                      </div>
                    </div>
                    <div class="p-1 bg-light mx-n3">
                      <h6 class="px-3">General Settings</h6>
                    </div>
                    <div class="p-2 text-left mt-3">
                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch4"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch4"
                        >
                          Show me Online
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch5"
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch5"
                        >
                          Status visible to all
                        </label>
                      </div>

                      <div class="custom-control custom-switch switch-primary mb-3">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="settings-switch6"
                          checked=""
                        />
                        <label
                          class="custom-control-label"
                          for="settings-switch6"
                        >
                          Notifications Popup
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
      }
    </div>
  )
}

export default Library