import React, { UseEffect, useState } from "react";
import { useEffect } from "react";
import logo from "../assets/images/ciwa.png"
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NavHeader from "../components/NavHeader";
import Footer from "../components/Footer";
import { asyncLocalStorage } from "../utils/appParams";
import { setCurrentUser } from "../utils/authService";
import api from "../api/api";

const PortalLogin = () => {
  const {state} = useLocation()
  const [username, setUsername] = useState(state ? state?.state?.id : "");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [btnText, setBtnText] = useState("Login")


  const navigate = useNavigate();

  const errorProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const warnProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const successProp = {
    position: "top-right",
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "colored",
  };

  const studentLogin = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      setBtnText('Loading...')
      const { data } = await api.post("/student/login", {
        username,
        password,
      });
      setLoading(false);
      // await asyncLocalStorage.setItem("student-token", data.data.tokens.access);
      localStorage.setItem("student-token", data.data.tokens.access);
      setCurrentUser(data.data.tokens.student);
      toast.success("Login successful", successProp);
      setBtnText('Login')
      navigate('/')
      // window.location.replace("/")
    } catch (error) {
      setBtnText("Failed, try again")
      setLoading(false);
      toast.error(error.response.data.error.message, errorProp);
    }
  };

const backgroundStyle = {
    backgroundImage: `url(/assets/images/auth_bg.jpeg)`,
    height: "100% !important",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    opacity:'01'
}

  return (
    <>
    {/* <NavHeader/> */}
    <div class="account-body" style={backgroundStyle}>
          <div style={{ content: '', backgroundSize: 'cover', position: 'absolute', top: 0, right: 0, bottom: 0, left: 0,backgroundColor: "rgba(0,0,139,0.7)"}}></div>
      <div class="container">
        <div class="row vh-100">
          <div class="col-lg-7 align-self-center">
          <div class="card-body">
                  <div class="px-3">
                    <div class="auth-logo-text text-center">
                            <img
                              src={logo}
                              alt=""
                              className="rounded-circle"
                              style={{height: "150px", objectFit:'contain', position:'relative'}}
                            />
                      <h6 class="text-white mb-2" style={{position:'relative'}}>
                        Welcome to
                      </h6>
                      <h1 class="mt-1 text-white" style={{position:'relative'}}>Catholic Institute of West Africa</h1>
                      <h4 class="mt-3 text-white" style={{position:'relative'}}>POSTGRADUATE PORTAL</h4>
                    </div>
                    
            </div>
            </div>
          </div>
          <div class="col-lg-5 align-self-center">
            <div class="auth-page">
              <div class="card auth-card shadow-lg dspg-light"  style={{opacity:"0.9"}}>
                <div class="card-body">
                  <div class="px-3">
                    <div class="text-center auth-logo-text">
                      <h4 class="mt-0 mb-3 mt-5">Post Graduate Portal Login</h4>
                      <p class="text-muted mb-0">
                        Sign in to your portal.
                      </p>
                    </div>

                    <form
                      class="form-horizontal auth-form my-4"
                      onSubmit={studentLogin}
                    >
                      <div class="form-group">
                        <label for="username">Application/Matric Number</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-user"></i>
                          </span>
                          <input
                            type="text"
                            class="form-control"
                            id="username"
                            placeholder="Enter application number"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div class="form-group">
                        <label for="userpassword">Password</label>
                        <div class="input-group mb-3">
                          <span class="auth-form-icon">
                            <i class="dripicons-lock"></i>
                          </span>
                          <input
                            type="password"
                            class="form-control"
                            id="userpassword"
                            placeholder="Enter password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                          />
                        </div>
                      </div>

                      <div class="form-group row mt-4">
                        <div class="col-sm-6">
                          <div class="custom-control custom-switch switch-success">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customSwitchSuccess"
                            />
                            <label
                              class="custom-control-label text-muted"
                              for="customSwitchSuccess"
                            >
                              Remember me
                            </label>
                          </div>
                        </div>
                        <div class="col-sm-6 text-right">
                          <a
                            href="/reset-password"
                            class="text-muted font-13"
                          >
                            <i class="dripicons-lock"></i> Forgot password?
                          </a>
                        </div>
                      </div>

                      <div class="form-group mb-0 row">
                          <div class="col-12 mt-2">
                            <button
                              class="btn btn-gradient-dark btn-round btn-block waves-effect waves-light"
                              type="submit"
                              disabled={loading}
                            >
                              {btnText} <i class="fas fa-sign-in-alt ml-1"></i>
                            </button>
                          </div>
                      </div>
                      <ToastContainer />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <Footer/> */}
    </div>
    
    </>

  );
};

export default PortalLogin;
