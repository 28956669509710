import logo from "../assets/images/ciwa.png";
const NavHeader = () => {
  return (
    <div data-layout="horizontal-tab">
      <div className="topbar"  style={{backgroundColor:'blue'}}>
        <div className="topbar">
          <div className="topbar-left text-center text-lg-left ml-4">
            <a href="#" className="logo">
              <span>
                <img src={logo} alt="logo-small" className="logo-sm" />
              </span>
              <span>
                {/* <img src="../assets/images/logo.png" alt="logo-large" className="logo-lg logo-light"/> */}
                <h4 className="logo-lg logo-light" style={{ color: "#fff" }}>
                  CIWA
                </h4>
                <img
                  src={logo}
                  alt="logo-large"
                  className="logo-lg logo-dark"
                />
              </span>
            </a>
          </div>
          {/* <nav className="navbar-custom">
        <div id="navigation">
            <ul className="list-unstyled navigation-menu">
                <li className="main-nav-item">
                    <a href="#" className="navbar-link">                                    
                        <span>Dashboard</span>
                    </a>                          
                </li>
                <li className="main-nav-item">
                    <a href="#" className="navbar-link">
                        <span>Apps</span>
                    </a>                          
                </li>
            </ul>
        </div>
    </nav>  */}
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
