import { useEffect } from "react";
import { useState } from "react";
import moment from "moment";
import { GeneralPaymentDetails, InitiateSchoolFeePayment } from "../../api/student";
import SchoolFeeComponent from "../../components/SchoolFeeComponent";
import Footer from "../../components/Footer";
import { getCurrentUser } from "../../utils/authService";
import { toast } from "react-toastify";
import usePayments from "../../hooks/usePayments";
import api from "../../api/api";
import Loader from "../../components/Loader";
import { useNavigate } from "react-router-dom";

const SchoolFees = () => {
  const user = getCurrentUser();
  const [schoolFee, setSchoolFee] = useState();
  const [loading, setLoading] = useState(false)
  const [paymentType, setPaymentType] = useState("")
  const [btnText, setBtnText] = useState("Generate Invoice");
  const {payment} = usePayments()
  const navigate = useNavigate()
  

  const getPaymentDetails = async () => {
    if (!paymentType) return
    setLoading(true)
    try {
      const { data } = await api.post('/student/generate-payment', {
        payment_type: paymentType,
        admission_id: user
      });
      console.log("Payment details",data)
      setSchoolFee(data);
      setLoading(false)
    } catch (error) {
      if (error.response.status === 400)  {
        toast.error("Please pay all compulsory fees to continue")
        setLoading(false)
        setPaymentType("")
      } else {
        toast.error("Something went wrong")
        setLoading(false)
      }
    }
  }

  const paySchoolFee = async () => {
    try {
        setBtnText("Initializing...")
      setLoading(true);
      const { data } = await InitiateSchoolFeePayment({
        admission_id: user,
        payment_type: 'pg school fee',
        payment_installment: paymentType
      });
      console.log(data, ',<<<,,<')
      navigate('/generate-invoice', {state: {_data:data, name:`School Fees (${paymentType})`}})
      // const {status, authorization_url, reference} = data
      // if (status && status === "success") {
      //   toast.info("Payment already made")
      //   setLoading(false)
      //   setBtnText("Proceed to payment")
      // } else {
      //   toast.success("Initiating payment");
      //   window.location.replace(authorization_url);
      //   setLoading(false);

      // }
    } catch (error) {
      setLoading(false);
      toast.error("Initiating payment failed!");
      setBtnText("Initiating payment failed!");
    }
  };


  useEffect(() => {
    getPaymentDetails()
  }, [paymentType]);
  return (
    <>
      <div class="page-wrapper">
        {(payment) && (
          <div class="page-content-tab">
            <div class="container-fluid">
              <div class="row">
                <div class="col-sm-12">
                  <div class="page-title-box">
                    <div class="float-right">
                      <ol class="breadcrumb">
                        {/* <li class="breadcrumb-item"><a href="javascript:void(0);">Metrica</a></li>
                            <li class="breadcrumb-item"><a href="javascript:void(0);">Pages</a></li> */}
                        <li class="breadcrumb-item active">Payment</li>
                      </ol>
                    </div>
                    <h4 class="page-title">Payment</h4>
                  </div>
                  {(!loading) && 
                  <SchoolFeeComponent
                    loading={loading}
                    btnText={btnText}
                    schoolee={schoolFee}
                    handlePayment={paySchoolFee}
                    paymentType={paymentType}
                    setPaymentType={setPaymentType}
                  />
                  
                 } 
               
                    {/* {(loading) &&
                    <Loader/>
                    } */}
                </div>
              </div>
            </div>
            <Footer />
          </div>
         )} 
      </div>
    </>
  );
};

export default SchoolFees;
