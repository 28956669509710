import React from "react";

const Loader = () => {
  return (
      <div class="page-wrapper d-flex d-flex justify-content-center spinner-border text-dark" role="status">
        <span class="sr-only">Loading...</span>
      </div>
  );
};

export default Loader;
